import _ from 'lodash';

export const noop = () => {};

export const capitalize = (val?: string) =>
  val ? val.charAt(0).toUpperCase() + val.slice(1) : val;

export const snakeToSpace = (val?: string) => val?.replaceAll('_', ' ');

export const toCamelCase = (obj: Record<string, any> | undefined) =>
  _.mapKeys(
    obj ?? {},
    // @ts-ignore
    (v: any, k: string) => _.camelCase(k),
  );

export const toSnakeCase = (obj: Record<string, any> | undefined) =>
  _.mapKeys(
    obj ?? {},
    // @ts-ignore
    (v: any, k: string) => _.snakeCase(k),
  );

export const bracket = (val?: string) => (!!val ? `(${val})` : val);

export const isValidEmail = (email?: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const encodeParams = (params: Record<string, any>): string =>
  Object.entries(params)
    .filter(([_, value]) => !!value || typeof value === 'boolean')
    .map(([key, value]) => [
      key,
      Array.isArray(value) ? value.join(',') : value,
    ])
    .map(([key, value]) => [key, value].map(encodeURIComponent).join('='))
    .join('&');

export const isValidPassword = (password: string) =>
  // - (?=.*\d?): optional number
  // - (?=.*[!@#$%^&*_]?): optional special char
  // - (?=.*[a-z]): min 1 lowercase letter
  // - (?=.*[A-Z]): min 1 uppercase
  // - {8,}: min 8 chars
  /^(?=.*\d?)(?=.*[!@#$%^&*_]?)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
