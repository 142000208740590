import { Auth } from '@aws-amplify/auth';
import { Hub, Logger } from '@aws-amplify/core';
import {
  ComponentType,
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { LaunchUriWrapper } from '../utils/oauth-config';
import {
  AUTH_PROVIDER_CHANNEL,
  AUTH_PROVIDER_EVT_SIGNED_IN,
  AUTH_PROVIDER_EVT_SIGNED_OUT,
} from './constants';
import { getUsername, setRedirUrl } from './utils';

const logger = new Logger('AuthProvider');

export const STATE_LOADING = 'loading';
export const STATE_SIGNIN = 'signIn'; // TODO - change to 'loggedOut' ?
export const STATE_SIGNEDIN = 'signedIn';

const AUTH_STATE_VALS = [STATE_LOADING, STATE_SIGNIN, STATE_SIGNEDIN] as const;

export type AuthStateVal = (typeof AUTH_STATE_VALS)[number];

type BaseAuthState = {
  signInUser: () => Promise<any>;
  signInUserWithCredentials: (
    username: string,
    password: string,
  ) => Promise<any>;
  signUpUser: () => Promise<any>;
  signOutUser: () => Promise<any>;
};

type AuthStateSignedIn = {
  authState: typeof STATE_SIGNEDIN;
  user: UserInfo;
  username: string;
};

type AuthStateNotSignedIn = {
  authState: typeof STATE_LOADING | typeof STATE_SIGNIN;
  user: null;
};

type AuthState = BaseAuthState & (AuthStateSignedIn | AuthStateNotSignedIn);

//

const BASE_AUTH_STATE: BaseAuthState = {
  signInUser: () => {
    setRedirUrl(); // remember where to redirect to
    return Auth.federatedSignIn();
  },
  signInUserWithCredentials: (username, password) => {
    setRedirUrl(); // remember where to redirect to
    return Auth.signIn(username, password);
  },
  signUpUser: () => {
    setRedirUrl(); // remember where to redirect to
    // HACK - LaunchUriWrapper will modify the URL to be signUp
    LaunchUriWrapper.setIsSignup(true);
    return Auth.federatedSignIn();
  },
  signOutUser: () => {
    setRedirUrl(); // remember where to redirect to
    return Auth.signOut();
  },
};

// type AuthLoadingState = AuthState['authState'];

const LOADING_STATE: AuthState = {
  authState: STATE_LOADING,
  user: null,
  ...BASE_AUTH_STATE,
};

const LOGGED_OUT_STATE: AuthState = {
  authState: STATE_SIGNIN,
  user: null,
  ...BASE_AUTH_STATE,
};

/**
 * Context for Amplify `AuthState`
 */
export const AuthStateContext = createContext<AuthState>(LOADING_STATE);

/**
 * Hook for reactive loading of AuthProvider state
 */
export const useAuthState = () => {
  return useContext(AuthStateContext);
};

/**
 * Provider component for the Amplify `AuthState`
 */
const AuthProvider: FunctionComponent<{}> = ({ children }) => {
  const [state, setState] = useState<AuthState>(LOADING_STATE);

  useEffect(() => {
    const destroy = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI': {
          lookupCurrentUserInfo()
            .then((user) => {
              if (user) {
                setState({
                  authState: STATE_SIGNEDIN,
                  user,
                  username: getUsername(user),
                  ...BASE_AUTH_STATE,
                });
              } else {
                logger.warn(
                  'No currentAuthenticatedUser, despite logged in!',
                  event,
                );
                setState(LOGGED_OUT_STATE);
              }
            })
            .catch((err) => {
              logger.warn('Error loading currentAuthenticatedUser');
              logger.error(err);
              setState(LOGGED_OUT_STATE);
            });
          break;
        }
        case 'signOut': {
          setState(LOGGED_OUT_STATE);
          break;
        }
        case 'signIn_failure':
        case 'cognitoHostedUI_failure': {
          logger.log('Sign in failure', data);
          setState(LOGGED_OUT_STATE);
          break;
        }
      }
    });

    lookupCurrentUserInfo()
      .then((user) => {
        if (user) {
          setState({
            authState: STATE_SIGNEDIN,
            user,
            username: getUsername(user),
            ...BASE_AUTH_STATE,
          });
        } else {
          setState(LOGGED_OUT_STATE);
        }
      })
      .catch((err) => {
        logger.warn('Error loading currentAuthenticatedUser');
        logger.error(err);
        setState(LOGGED_OUT_STATE);
      });

    return destroy;
  }, []);

  // support Hub.dispatch for previous listeners
  useEffect(() => {
    let event: string | undefined;
    switch (state.authState) {
      case STATE_SIGNIN: {
        event = AUTH_PROVIDER_EVT_SIGNED_OUT;
        break;
      }
      case STATE_SIGNEDIN: {
        event = AUTH_PROVIDER_EVT_SIGNED_IN;
        break;
      }
    }
    if (event) {
      Hub.dispatch(
        AUTH_PROVIDER_CHANNEL,
        { event, data: state },
        'AuthProvider',
      );
    }
  }, [state.authState]);

  return (
    <AuthStateContext.Provider value={state}>
      {children}
    </AuthStateContext.Provider>
  );
};

export default AuthProvider;

// ## Helpers

/**
 * Pass the current `AuthState` data into props
 * TODO(REMMM) - replace `connectAuth`
 */
export const withAuthUser = <P extends AuthState>(Comp: ComponentType<P>) => {
  type WrappedProps = Omit<P, keyof P>;

  const Wrapped: FunctionComponent<WrappedProps> = (props) => {
    const authState = useContext(AuthStateContext);
    const newProps = { ...props, ...authState } as P;
    // @ts-ignore - TODO - fix react types error
    return <Comp {...newProps} />;
  };

  return Wrapped;
};

/**
 * Click event handler to load the hosted UI
 */
export const handleSignInUser = (evt?: React.MouseEvent) => {
  evt?.preventDefault();
  return BASE_AUTH_STATE.signInUser();
};

export const handleSignInUserWithCredentials = (
  username: string,
  password: string,
) => {
  return BASE_AUTH_STATE.signInUserWithCredentials(username, password);
};

/**
 * Click event handler to load the hosted UI
 */
export const handleSignUpUser = (evt: React.MouseEvent) => {
  evt.preventDefault();
  return BASE_AUTH_STATE.signUpUser();
};

/**
 * Click event handler to sign out the current user
 */
export const handleSignOutUser = (evt: React.MouseEvent) => {
  evt.preventDefault();
  return BASE_AUTH_STATE.signOutUser();
};

//

export type UserInfo = {
  /** Identity pool id for the user & fpusers DynamoDB userId */
  id: string;
  /** User pool username (either the Cognito sub or federated provider + id) */
  username: string;
  attributes: {
    /** The user's email  */
    email: string;
    /** Whether the email has been verified (we auto-verify all) */
    email_verified: boolean;
    /** First name (if auto-grabbed from federated identity) */
    given_name?: string;
    identities?: string;
    /** Name (if auto-grabbed from federated identity) */
    name?: string;
    /** Cognito User pool sub */
    sub: string;
    /** Identity pool id (should match id, this exists so we can map cognito users to dynamodb users) */
    'custom:identity_id'?: string;
  };
};

async function lookupCurrentUserInfo() {
  // NOTE: we can use Auth.currentAuthenticatedUser() or Auth.currentUserInfo().
  // Both return `{ username: string, attributes: object }`, but the prior
  // has a bunch of the authentication data, while the latter has the identity
  // pool id as `{ id }`, and we use that as the userId in the application, so
  // let's use that
  return Auth.currentUserInfo() as Promise<UserInfo | null>;

  // return Auth.currentAuthenticatedUser().then(
  //   (userData) => userData as CognitoUser
  // );
}
