import { launchUri } from '@aws-amplify/auth/src/OAuth/urlOpener';
import aws_exports from '../aws-exports';

const APP_ENDPOINT = `${window.location.protocol}//${window.location.host}`;

/**
 * Wrapper element that can hijack OAuth opens and
 * change the URL to be signup instead of login
 */
export const LaunchUriWrapper = (function () {
  const self = {
    _isSignUp: false,
    _updateAsSignUp: (url: string) => {
      return url.replace('/oauth2/authorize?', '/signup?');
    },
    /**
     * Set the next `Auth.federatedSignIn()` to open signup
     * instead of login
     * @param enable whether to set it or not (defaults to true)
     */
    setIsSignup: (enable?: boolean) => {
      self._isSignUp = enable == null ? true : enable;
    },
    /**
     * urlOpener function to pass to aws_exports['oauth']['urlOpener']
     * If `self._isSignUp`, then tries to convert the URL
     * to a signup URL and reverts the value after.
     */
    launchUri: (url: string) => {
      if (self._isSignUp) {
        url = self._updateAsSignUp(url);
      }
      return launchUri(url)
        .then((resp) => {
          self.setIsSignup(false);
          return resp;
        })
        .catch((err) => {
          self.setIsSignup(false);
          throw err;
        });
    },
  };
  return self;
})();

/**
 * Config overrides
 */
export const oauth = {
  // Domain name
  domain:
    // @ts-ignore
    process.env.OAUTH_DOMAIN ||
    `fpauth${
      (process.env.ENV && '-' + process.env.ENV) || ''
    }.auth.us-east-1.amazoncognito.com`,
  // Authorized scopes
  scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  // Callback URL
  redirectSignIn: `${APP_ENDPOINT}/auth/signin/`,
  // Sign out URL
  redirectSignOut: `${APP_ENDPOINT}/auth/signout/`,
  // 'code' for Authorization code grant, 'token' for Implicit grant
  responseType: 'code',
  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true,
  },
  urlOpener: LaunchUriWrapper.launchUri,
};

/**
 * The aws-exports with our custom oauth values. Use this
 * instead of aws-exports
 */
export const awsConfig = {
  ...aws_exports,
  oauth,
};
