import { logABEvent as apiLogABEvent } from './api';
import { IS_MASTER, STORE_KEY_AB } from './constants';
import { log } from './logger';
import { checkRememberedEvent, rememberEvent } from './utils/event-ratelimiter';
import localStorage from './utils/localStorage';

// Functions

// TODO(dry) - these constants exist in webhooks/src/ab-util.js

const VISIT_SITE = 'visit_site'; // DUPE(webhooks/ab-test.js)
const CHECKOUT_ACCOUNT = 'checkout_account'; // DUPE(webhooks/ab-test.js)
const CHECKOUT_PAYMENT = 'checkout_payment'; // DUPE(webhooks/ab-test.js)
const CHECKOUT_SUCCESS = 'checkout_success'; // DUPE(webhooks/ab-test.js)
const CANCEL_SUBSCRIPTION = 'cancel_trial'; // DUPE(webhooks/ab-test.js)
const DISABLE_RENEW = 'disable_renew'; // DUPE(webhooks/ab-test.js)
const ENABLE_RENEW = 'enable_renew'; // DUPE(webhooks/ab-test.js)
const USER_ACCOUNT = 'user_account'; // DUPE(webhooks/ab-test.js)

export const logVisitSite = () => _logEvent(VISIT_SITE);
export const logCheckoutAccount = () => _logEvent(CHECKOUT_ACCOUNT);
export const logCheckoutPayment = (userId) =>
  _logEvent(CHECKOUT_PAYMENT, userId);
export const logCheckoutSuccess = (userId) =>
  _logEvent(CHECKOUT_SUCCESS, userId);
export const logCancelSub = (userId) => _logEvent(CANCEL_TRIAL, userId, false);
export const logDisableRenew = (userId) =>
  _logEvent(DISABLE_RENEW, userId, false);
export const logEnableRenew = (userId) =>
  _logEvent(ENABLE_RENEW, userId, false);
export const logUserAccount = (userId) => _logEvent(USER_ACCOUNT, userId);

const _getToken = () => {
  const abData = getAb();
  return (abData && abData.token) || null;
};

const _logEvent = async (eventName, userId = null, rateLimit = true) => {
  const token = _getToken();
  if (token) {
    // wait 30 minutes before triggering an event of the
    // same name twice
    if (rateLimit) {
      if (checkRememberedEvent(eventName, token)) {
        log(`>>>>> SKIP EVENT[${eventName}] <<<<<<`);
        return null;
      }
      rememberEvent(eventName, token);
    }

    try {
      return apiLogABEvent(token, eventName, userId);
    } catch (e) {
      log.error(e);
    }
  }
  return null;
};

/**
 * Return whether or not the current user should see the Premium UI
 *
 * @return {boolean}
 */
export const getShowPremium = () => {
  return true;

  /*
  // check if is in ab test
  const data = getAb();
  if (data) {
    return true;
  }

  // check if has aws.congito info in store
  let hasStor = false;
  if (!data) {
    let keys = [];
    try {
      keys = Object.keys(window.localStorage);
    } catch (e) {}
    hasStor = keys.some(x => x.startsWith('aws.cognito.'));
  }
  if (hasStor) {
    return true;
  }

  // check for force premium param
  const FORCE_PREMIUM_KEY = '__fpforcepremium';
  const FORCE_PREMIUM_VAL = '1';
  const urlpath = window.location.href
    .split('/')
    .slice(3)
    .join('/');

  let forcePremium;
  if (urlpath === 'account?premium=1') {
    forcePremium = true;
    localStorage.setItem(FORCE_PREMIUM_KEY, FORCE_PREMIUM_VAL);
    const newUrl = '/account';
    if (window.history && window.history.replaceState) {
      window.history.replaceState(window.history.state || null, '', newUrl);
    } else {
      window.location = newUrl;
    }
  } else {
    forcePremium =
      localStorage.getItem(FORCE_PREMIUM_KEY) === FORCE_PREMIUM_VAL;
  }
  if (forcePremium) {
    return true;
  }

  return false;
  */
};

/**
 * Set the ab data json in local storage
 *
 * @param {string} ab - the string representation of the ab data
 * @return {object | null}
 */
export const setAbJson = (ab) => {
  const [err, data] = _parse(ab, 'object');
  if (!err) {
    try {
      ab = btoa(ab); // try base64 encode
    } catch (e) {}
    localStorage.setItem(STORE_KEY_AB, ab);
    return true;
  }
  return false;
};

/**
 * Return the value from local storage for the ab data
 *
 * @return {string | null}
 */
export const getAbJson = () => {
  let val = localStorage.getItem(STORE_KEY_AB);
  if (val) {
    try {
      val = atob(val); // try base64 decode
    } catch (e) {}
  }
  return val;
};

/**
 * Load the ab data from local storage and try to parse it
 * if everything goes well return that object, o/w null
 *
 * @return {object | null}
 */
export const getAb = () => {
  const json = getAbJson();
  const [error, data] = _parse(json, 'object');
  return error ? null : data;
};

/**
 * Load the underlying data within the ab data that has
 * 'amount' and 'planId', or if not available, return `null`
 *
 * @return {object | null}
 */
export const getAbPlanData = () => {
  // HARDCODED - we have DEFAULT_PLAN_AMOUNT in extension constants.js
  const defaultPlan = IS_MASTER
    ? // master, default plan
      {
        showPremium: true,
        planId: 'plan_Fxlm5RpmvLwiAf',
        amount: 1200,
      }
    : // dev, default plan
      {
        showPremium: true,
        planId: 'plan_FxlgjNjD7oVDaH',
        amount: 1200,
      };

  const ab = getAb();
  // HACK - prevent old ab tests for $24/yr and $48/yr from sneaking in
  if (ab && ab.data && ab.data.amount !== 2400 && ab.data.amount !== 4800) {
    const data = ab.data;
    const keys = Object.keys(defaultPlan);
    if (keys.every((k) => data[k])) {
      return data;
    }
  }

  return defaultPlan;
};

// Helpers

const _parse = (str, expectedType) => {
  let data = null;
  let error = null;
  try {
    if (typeof str !== 'string') {
      const e = new Error(
        `Expected a string to parse, but received ${typeof str}: ${str}`,
      );
      e.name = 'InvalidJsonStringError';
      throw e;
    }

    data = JSON.parse(str);

    if (expectedType && typeof data !== expectedType) {
      const e = new Error(
        `Expected type ${expectedType} but received ${typeof data}`,
      );
      e.name = 'TypeMismatchError';
      throw e;
    }
  } catch (e) {
    error = e;
  }
  return [error, data];
};
