import assert from 'minimalistic-assert';

const COOKIE_PREFIX = '__fpck';
const DEFAULT_MAX_AGE = 30 * 60; // 30 minutes

export const rememberEvent = (eventName, token, maxAge = DEFAULT_MAX_AGE) => {
  assert(typeof eventName === 'string', `bad eventName: ${eventName}`);
  assert(typeof maxAge === 'number' && maxAge > 0, `bad maxAge: ${maxAge}`);

  const key = _asKey(eventName);
  const val = _asVal(token);

  document.cookie = `${key}=${val};path=/;max-age=${maxAge};`;
};

export const checkRememberedEvent = (eventName, token) => {
  const key = _asKey(eventName);
  const val = _asVal(token);

  for (let tok of document.cookie.split(';')) {
    const [k, v] = tok.trim().split('=');
    if (k === key && v === val) {
      return true;
    }
  }

  return false;
};

const _asKey = (eventName) => {
  // this is hash-like, lol, which is ok for usage in this
  // module, but not as an actual hash
  let t = eventName.toLowerCase().replace(/[^a-z]+/g, '');
  t = btoa(t).replace(/=+/g, '').toLowerCase().substring(0, 20);
  return `${COOKIE_PREFIX}${t}`;
};

const _asVal = (val) =>
  (val || '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z]+/g, '');
