import moment from 'moment';

// TODO - these all mutate, we should instead generate new objects!

// ## Functions

export const parseAccountOrOrg = (account) => {
  if (account) {
    if (account.subscription) {
      parseSubscription(account.subscription);
    }
    if (account.payments) {
      account.payments.forEach(parsePayment);
    }
  }
  return account;
};

export const parseSubscription = (subscription) =>
  convertTimestamps(subscription, [
    'current_period_end',
    'current_period_start',
    'trial_start',
    'trial_end',
  ]);

export const parsePayment = (payment) => {
  convertTimestamps(payment, ['created']);
  (payment.refunds || []).forEach((refund) => {
    convertTimestamps(refund, ['created']);
  });
  return payment;
};

// ## Helpers

const convertTimestamps = (obj, keys) => {
  keys.forEach((key) => {
    if (obj[key] !== undefined) {
      obj[key] = unixTimestampToMoment(obj[key]);
    }
  });
  return obj;
};

// Stripe timestamps are in seconds
const unixTimestampToMoment = (ts) => moment.unix(ts);
