export const API_NAME = 'fpapi';

export const HUB_CHANNEL = 'api-helper';
export const HUB_SOURCE = 'api-module';

export const PATH_ACCOUNT_PREFIX = 'account';
export const PATH_CURRENT_SUB = '/account';
export const PATH_SIMPLE = '/account/simple';

export const PATH_CHECKOUT_SESSION = '/account/checkout-stripe';
export const PATH_CHECKOUT_SESSION_SUCCESS = '/account/checkout-stripe-success';

export const PATH_CHECKOUT_UPDATE_PAYMENT_URL =
  '/account/checkout-stripe-update';
export const PATH_CHECKOUT_UPDATE_PAYMENT_SUCCESS =
  '/account/checkout-stripe-update-success';

export const PATH_UPDATE_EMAIL = '/account/update-email';

export const PATH_AB = '/webhooks/ab';
