const DEFAULT_STYLE = 'color: green';

import logUtil, { LOG_LEVELS, setLogLevel } from './utils/log';

// ENABLE LOGGING:  window.localStorage.setItem('__fpdolog', '1')
// DISABLE LOGGING: window.localStorage.setItem('__fpdolog', null)

try {
  const forceLogKey = '__fpdolog';
  let item = window.localStorage.getItem(forceLogKey);
  if (item === '1') {
    console.log(
      `%cSetting log debug because "${forceLogKey}" is enabled`,
      'background:black;color:yellow',
    );
    setLogLevel(LOG_LEVELS.DEBUG);
  }
} catch (e) {}

export const log = logUtil;

export const genColorLog = (style = DEFAULT_STYLE) =>
  function () {
    const args = _colorifyArguments(arguments, style);
    log.apply(log, args);
  };

export const genColorLogGroup = (style = DEFAULT_STYLE) =>
  function () {
    const args = _colorifyArguments(arguments, style);
    log.group.apply(log, args);
  };

export const colorLog = genColorLog();
export const colorLogGroup = genColorLogGroup();
export const colorLogGroupEnd = () => log.groupEnd();

// helpers

const _colorifyArguments = (args, style) => {
  args = Array.prototype.slice.call(args);
  args[0] = `%c${args[0]}`;
  args.splice(1, 0, style);
  return args;
};
