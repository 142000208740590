let _CACHE: Record<string, string | undefined> = {};

const localStorage = {
  getItem: (key: string) =>
    window.localStorage
      ? window.localStorage.getItem(key)
      : key in _CACHE
        ? _CACHE[key]
        : null,
  setItem: (key: string, val: string) => {
    window.localStorage
      ? window.localStorage.setItem(key, val)
      : (_CACHE[key] = val);
  },
  removeItem: (key: string) => {
    window.localStorage
      ? window.localStorage.removeItem(key)
      : delete _CACHE[key];
  },
  clear: () => {
    window.localStorage ? window.localStorage.clear() : (_CACHE = {});
  },
};

export default localStorage;
