export const tryNTimes = <T extends Array<any>, U>(
  n: number,
  fn: (...args: T) => Promise<U>,
) => {
  return async function (...args: T) {
    let i = n;
    while (true) {
      i--;
      let ret: U | null = null;
      try {
        ret = await fn(...args);
      } catch (err) {
        if (i <= 0) {
          throw err;
        }
      }
      return ret;
    }
  };
};

export const sleep = (delay: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, delay));
