export const getIsEdge = () => {
  if (window.navigator && window.navigator.userAgent) {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('edge') !== -1 || ua.indexOf('edg/') !== -1;
  }
  return false;
};

export const getIsMac = () => {
  if (window.navigator && window.navigator.userAgent) {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('mac') !== -1;
  }
  return false;
};
