import PropTypes from 'prop-types';

// ## "Enum" of authState values

export const STATES = {
  loading: 'loading',
  signIn: 'signIn',
  // NOTE: with hosted UI these states should not happen...?
  // signUp: 'signUp',
  // confirmSignIn: 'confirmSignIn',
  // confirmSignUp: 'confirmSignUp',
  // forgotPassword: 'forgotPassword',
  // verifyContact: 'verifyContact',
  signedIn: 'signedIn',
};

// ## Auth state channel
//
// custom Hub channel and event - so you can listen to
// get the actual `payload.data.user` when we properly login
// and `payload.data.event === AUTH_PROVIDER_EVT_SIGNED_IN`

export const AUTH_PROVIDER_CHANNEL = 'auth-provider';

export const AUTH_PROVIDER_EVT_SIGNED_IN = 'signedIn';
export const AUTH_PROVIDER_EVT_SIGNED_OUT = 'signedOut';
