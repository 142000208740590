import { API } from '@aws-amplify/api';
import { Hub } from '@aws-amplify/core';
import { SUPPORT_EMAIL } from '../constants';

import { genColorLog, genColorLogGroup, log } from '../logger';
import { tryNTimes } from '../utils/promise-util';

import { encodeParams } from '../utils/utils';
import {
  API_NAME,
  HUB_CHANNEL,
  HUB_SOURCE,
  PATH_AB,
  PATH_CHECKOUT_SESSION,
  PATH_CHECKOUT_SESSION_SUCCESS,
  PATH_CHECKOUT_UPDATE_PAYMENT_SUCCESS,
  PATH_CHECKOUT_UPDATE_PAYMENT_URL,
  PATH_CURRENT_SUB,
  PATH_SIMPLE,
  PATH_UPDATE_EMAIL,
} from './constants';
import * as Transforms from './transforms';

window.API = API; // TODO(NEXT) - remove this

const STYLE = 'color:#C02;background:#FDD;';
const colorLog = genColorLog(STYLE);
const colorLogGroup = genColorLogGroup(STYLE);

/**
 * @typedef {{
 *   includePayments?: boolean;
 *   includeAbTest?: boolean;
 *   forceAbTestV2Group?: import('../types/Account').AbTestV2["group"];
 * }} LookupAccountOpts;
 */

/**
 * @typedef {import('../types/Account').ApiAccount} ApiAccount;
 * @typedef {import('../types/Account').Account} Account;
 * @typedef {import('../types/Account').ApiOrganization} ApiOrganization;
 * @typedef {import('../types/Account').Organization} Organization;
 */

/**
 * API request to lookup the current user's account
 * @param {LookupAccountOpts} [opts] options for including payments or ab test info in the response or forcing a testing group
 * @returns {Promise<Account>}
 */
export const lookupAccount = (opts) => {
  if (window.location.hash === '#test-error') {
    const err = new Error('TestError');
    return Promise.reject(err);
  }

  const path = PATH_CURRENT_SUB;
  const getOpts = {};

  let hasParams = false;
  const queryStringParameters = {};

  if (opts && opts.includePayments) {
    queryStringParameters.payments = true;
    hasParams = true;
  }
  if (opts && opts.includeAbTest) {
    queryStringParameters.ab = true;
    hasParams = true;
  }
  if (opts && opts.forceAbTestV2Group) {
    queryStringParameters.forceAbTestV2Group = opts.forceAbTestV2Group;
    hasParams = true;
  }

  if (hasParams) {
    getOpts.queryStringParameters = queryStringParameters;
  }

  return API.get(API_NAME, path, getOpts)
    .then(_parseAccount('Error looking up subscription', '[lookupAccount]'))
    .then(_hubNotify('get', path));
};

/**
 * API request to update cancel at period end status
 * @param {boolean} cancelAtPeriodEnd
 * @returns {Promise<Account>}
 */
export const updateAccountCancelAtPeriodEnd = (cancelAtPeriodEnd) => {
  const path = PATH_CURRENT_SUB;
  return API.post(API_NAME, path, {
    body: { cancel_at_period_end: cancelAtPeriodEnd },
  })
    .then(
      _parseAccount(
        'Error changing cancel at period end',
        '[updateAccountCancelAtPeriodEnd]',
      ),
    )
    .then(_hubNotify('post', path));
};

/**
 * API request to cancel a subscription
 * @returns {Promise<Account>}
 */
export const cancelAccountSubscription = () => {
  const path = PATH_CURRENT_SUB;
  return API.del(API_NAME, PATH_CURRENT_SUB)
    .then(
      _parseAccount(
        'Error canceling subscription',
        '[cancelAccountSubscription]',
      ),
    )
    .then(_hubNotify('del', path));
};

/**
 * API request to create a stripe checkout session URL
 * @param {{
 *   plan: string;
 *   cancel_url: string;
 *   success_url: string;
 * }} body
 * @returns {Promise<string>}
 */
export const createCheckoutSessionUrl = (body) => {
  const path = PATH_CHECKOUT_SESSION;
  return API.post(API_NAME, path, { body })
    .then(_parseResp('url', 'Error loading checkout'))
    .then(_hubNotify('post', path));
};

/**
 * API request to record a succcessful stripe checkout session
 * @param {{ session_id: string; }} body
 * @returns {Promise<Account>} (needs Account type)
 */
export const performCheckoutSessionSuccess = (body) => {
  const path = PATH_CHECKOUT_SESSION_SUCCESS;

  return API.post(API_NAME, path, { body })
    .then(
      _parseAccount(
        'Error performing checkout',
        '[performCheckoutSessionSuccess]',
      ),
    )
    .then(_hubNotify('post', path));
};

/**
 * API request to generate an update payment success URL
 * via Stripe Checkout
 * @param {{
 *   cancel_url: string;
 *   success_url: string;
 *   account_type?: import('../types/Account').AccountType
 * }} body
 * @returns {Promise<string>}
 */
export const createUpdatePaymentSessionUrl = (body) => {
  const path = PATH_CHECKOUT_UPDATE_PAYMENT_URL;
  return API.post(API_NAME, path, { body })
    .then(_parseResp('url', 'Error loading update payment page.'))
    .then(_hubNotify('post', path));
};

/**
 * API request to complete an update payment via Stripe
 * Checkout
 * @param {{
 *   session_id: string;
 *   account_type?: import('../types/Account').AccountType;
 * }} body
 * @returns {Promise<{
 *   type: "user";
 *   data: Account;
 * } | {
 *   type: "organization";
 *   data: Organization;
 * }>}
 */
export const performUpdatePaymentSessionSuccess = (body) => {
  const path = PATH_CHECKOUT_UPDATE_PAYMENT_SUCCESS;

  const account_type = { body };
  return API.post(API_NAME, path, { body })
    .then((data) => {
      if (data.type === 'organization') {
        return {
          type: 'organization',
          data: _parseOrganization(
            'Error performing enterprise checkout',
            '[performUpdatePaymentSessionSuccess]',
          )(data),
        };
      } else {
        return {
          type: 'user',
          data: _parseAccount(
            'Error performing checkout',
            '[performUpdatePaymentSessionSuccess]',
          )(data),
        };
      }
    })
    .then(_hubNotify('post', path));
};

export const performChangeEmail = (email) => {
  const path = PATH_UPDATE_EMAIL;
  return API.post(API_NAME, path, { body: { email } });
};

/**
 * Query the current users account
 * @returns {Promise<{
 *   userId: string;
 *   expires: number;
 *   status: import('../types/Account').StripeSubscriptionStatus;
 * }>}
 */
export const lookupSimpleAccount = () => {
  const path = PATH_SIMPLE;

  // NOTE - no _hubNotify on this one since currently
  // some other code (extension/login.LoginListener)
  // expects all HUB_CHANNEL for 'account' to
  // match the other calls in this function...
  return API.get(API_NAME, path)
    .then(_parseResp('object', 'Unable to lookup user'))
    .then(_hubNotify('get', path));
};

/**
 * Query an organization
 * @param {string} orgId
 * @returns {Promise<Organization>}
 */
export const getOrganizationDetails = (orgId) =>
  API.get(API_NAME, `/v1/organizations/${orgId}`, null).then((_org) => {
    // HACK - convert to match `{ ok: boolean; [object_name]: object }` format
    // some error cases do this while everything else does not
    //

    if (_org.ok == null) {
      _org = { ok: true, organization: _org };
    }

    return _parseOrganization('[getOrganizationDetails]')(_org);
  });

export const getOrganizationMembers = (orgId) =>
  API.get(API_NAME, `/v1/organizations/${orgId}/members`, null);

export const sendEnterpriseMembersInvitations = (orgId, members) =>
  API.post(API_NAME, `/v1/organizations/${orgId}/members`, {
    body: members,
  });

export const updateEnterpriseMemberStatus = (orgId, memberId, orgStatus) =>
  API.post(API_NAME, `/v1/organizations/${orgId}/members/${memberId}`, {
    body: { orgStatus },
  });

export const revokeEnterpriseMemberInvite = (orgId, memberId) =>
  API.post(
    API_NAME,
    `/v1/organizations/${orgId}/members/${memberId}/uninvite`,
    null,
  );

export const getInviteDetails = (sub, token) =>
  API.get(API_NAME, `/v1/invites/one?${encodeParams({ sub, token })}`, null);

export const acceptInvite = (sub, token, password) =>
  API.post(API_NAME, `/v1/invites/one?${encodeParams({ sub, token })}`, {
    body: { password },
  });

export const declineInvite = (sub, token) =>
  API.del(API_NAME, `/v1/invites/one?${encodeParams({ sub, token })}`, null);

const _logABEvent = (browserToken, eventName, userId) => {
  const path = PATH_AB;
  const body = {
    token: browserToken,
    event: eventName,
    url: window.location.href,
  };
  if (userId) {
    body.userId = userId;
  }
  colorLog(`[API ${path}] ${eventName}`, browserToken);
  return API.post(API_NAME, path, { body })
    .then(_parseResp('data', 'Unable to log event'))
    .then(_hubNotify('post', path))
    .then((ret) => {
      colorLog(`[API ${path}].then`, ret);
      return ret;
    });
};

export const logABEvent = tryNTimes(2, _logABEvent);

// helpers

const _hubNotify = (method, path) => (object) => {
  const pathPrefix = path.split('/')[1] || '';
  Hub.dispatch(
    HUB_CHANNEL,
    {
      event: path,
      data: {
        method,
        object,
        pathPrefix,
      },
    },
    HUB_SOURCE,
  );
  return object;
};

export const ERROR_CODE_ATTR_NOT_FOUND = 'AttrNotFoundError';
export const ERROR_CODE_RETRY = 'RetryError';

/**
 * Create a function that will extract the value at `attr` from
 * the a response if `resp.ok`, otherwise throw an exception.
 *
 * @param {string} attr the expected attr to extract and return (unless it is blank)
 * @param {string} condErrMsg backup message if resp.error is falsey
 * @returns {(resp: { [key: string]: any }) => any} either the value extracted from resp or an `Error` with a usage .message attr (needs generic typing)
 */
const _parseResp = (attr, condErrMsg) => (resp) => {
  if (resp.ok) {
    if (resp[attr] == null) {
      // TODO(zendesk) - offer error reporting for this?
      const err = new Error(
        `Attr not found: ${attr}, please contact support@gofullpage.com`,
      );
      err.name = ERROR_CODE_ATTR_NOT_FOUND;
      err.code = err.name;
      err.is_expected = true;
      throw err;
    } else {
      return resp[attr];
    }
  } else {
    const err = new Error(resp.error || condErrMsg);
    err.name = 'APIError';
    err.code = err.name;
    if (resp.error_code === 'retry') {
      // HACK - special case for retry errors -- this came
      // up from an issue where new "continue with google"
      // accounts appear to have a race condition where they
      // briefly don't exist, yet, even thought we have a user
      // account...
      err.code = ERROR_CODE_RETRY;
    }
    err.is_expected = true;
    throw err;
  }
};

export const asErrorMsg = (error) => {
  let is_expected = false;
  let msg = '';
  try {
    msg = error.message;
    is_expected = Boolean(error.is_expected);
  } catch (exc) {
    msg = String(error);
  }

  return is_expected
    ? msg
    : `Unexpected error: ${msg}. Please contact ${SUPPORT_EMAIL}`;
};

/**
 * Create a function to Parse an API response for an
 * account lookup and return an Account object.
 *
 * @param {string} condErrMsg
 * @returns {(resp: { ok: boolean; account?: ApiAccount; }) => Account}
 */
const _parseAccount = (condErrMsg, debug) => (resp) => {
  /*
  // TODO(typescript)
  type Response<D> = { success: boolean } & D;
  type AccountResponse = Response<{ account: Account}>;
  */
  let account = _parseResp('account', condErrMsg)(resp);
  account = Transforms.parseAccountOrOrg(account);
  colorLogGroup('[API /account]', debug);
  log(account);
  log.groupEnd();
  return account;
};

/**
 * Create a function to Parse an API response for an
 * organization lookup and return an Organization object.
 *
 * @param {string} condErrMsg
 * @returns {(resp: {
 *   ok: boolean;
 *   organization?: ApiOrganization;
 * }) => Organization}
 */
const _parseOrganization = (condErrMsg, debug) => (resp) => {
  let org = _parseResp('organization', condErrMsg)(resp);
  org = Transforms.parseAccountOrOrg(org);
  colorLogGroup('[API /v1/organizations]', debug);
  log(org);
  log.groupEnd();
  return org;
};
