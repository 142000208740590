//
// ## Logging
//
export const LOG_LEVELS = {
  // CRITICAL: 50,
  ERROR: 40,
  WARN: 30,
  INFO: 20,
  DEBUG: 10,
  NOTSET: 0,
};

// @ts-ignore
let LOG_LEVEL = process.env.DEBUG === '1' ? LOG_LEVELS.DEBUG : LOG_LEVELS.INFO;

export const getLogLevel = () => LOG_LEVEL;
export const setLogLevel = (level: number) => (LOG_LEVEL = level);

const _log = (level: number, args: any[], fnName?: string) => {
  if (level < LOG_LEVEL) {
    return;
  }

  // @ts-ignore
  if (!fnName || !console[fnName]) {
    fnName =
      level <= LOG_LEVELS.DEBUG
        ? 'log'
        : level <= LOG_LEVELS.INFO
          ? 'info'
          : level <= LOG_LEVELS.WARN
            ? 'warn'
            : 'error';
  }
  try {
    // @ts-ignore
    let fn = console[fnName] || console.log;
    fn.apply(console, args);
  } catch (e) {}
};

const log = function _debug(...args: any[]) {
  _log(LOG_LEVELS.DEBUG, args);
};

export default log;

log.debug = log;
log.info = function _info(...args: any[]) {
  _log(LOG_LEVELS.INFO, args);
};
log.warn = function _warn(...args: any[]) {
  _log(LOG_LEVELS.WARN, args);
};
log.error = function _error(...args: any[]) {
  _log(LOG_LEVELS.ERROR, args);
};
log.group = function _group(...args: any[]) {
  _log(LOG_LEVELS.DEBUG, args, 'group');
};
log.groupCollapsed = function _groupCollapsed(...args: any[]) {
  _log(LOG_LEVELS.DEBUG, args, 'groupCollapsed');
};
log.groupEnd = function _groupEnd(...args: any[]) {
  _log(LOG_LEVELS.DEBUG, args, 'groupEnd');
};
log.j = function _json(d: any) {
  if (d === undefined) {
    return 'undefined';
  }
  try {
    return JSON.stringify(d);
  } catch (e) {
    return d;
  }
};

export const groupCollapsedFn = <F extends Function>(msg: string, fn: F) =>
  groupFn(msg, fn, true);

export const groupFn = <F extends Function>(
  msg: string,
  fn: F,
  isCollapsed?: boolean,
): F => {
  function wrapped() {
    log[isCollapsed === true ? 'groupCollapsed' : 'group'](msg);
    try {
      // @ts-ignore
      let result = fn.apply(this, arguments);
      if (_isPromise(result)) {
        let ended = false;
        return result
          .then((val: any) => {
            ended = true;
            log.groupEnd();
            return val;
          })
          .catch((err: Error) => {
            if (!ended) {
              ended = true;
              log.groupEnd();
            }
            throw err;
          });
      } else {
        log.groupEnd();
        return result;
      }
    } catch (e) {
      log.groupEnd();
      throw e;
    }
  }

  return wrapped as unknown as F;
};

const _isPromise = (x: any) =>
  x !== null &&
  typeof x === 'object' &&
  typeof x.then === 'function' &&
  typeof x.catch === 'function';
