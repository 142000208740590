import { Hub } from '@aws-amplify/core';

// ### Cookie based get/set state for URL redirect after signin/out

const COOKIE_KEY = '_redir';

/**
 * Remember the URL, so after hosted login we can
 * redirect back there!
 * @param {string} [redir] the URL to redirect back to or
 *     the current URL
 */
export const setRedirUrl = (redir) => {
  if (!redir) {
    redir = window.location.href.replace(/^(https?:\/\/[^\/]+)/, '');
  }
  document.cookie = `${COOKIE_KEY}=${encodeURIComponent(
    redir,
  )}; expires=0; path=/`;
};

/**
 * Look up the remembered redirect URL (from `setRedirUrl`),
 * this is used to redirect after hosted login.
 * @param {string} [defaultUrl]
 * @returns the path, querystring, and hash
 */
export const getRedirUrl = (defaultUrl = '/') => {
  const token = document.cookie
    .split('; ')
    .find((tok) => tok.startsWith(`${COOKIE_KEY}=`));

  let val = '';

  // get val
  if (token) {
    val = token.split('=')[1];
    if (val) {
      val = decodeURIComponent(val);
    }
  }

  // sanitize val
  if (!val.startsWith('/')) {
    val = '';
  }

  return val || defaultUrl;
};

getRedirUrl.COOKIE_KEY = COOKIE_KEY; // for debugging

// ### User helpers

export const getUsername = (user, defaultName = 'user') => {
  if (!user) {
    return defaultName;
  }

  const nameFromAttr = user.attributes
    ? user.attributes.given_name ||
      user.attributes.name ||
      user.attributes.email
    : undefined;

  return nameFromAttr || defaultName;
};
